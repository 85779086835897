// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actualites-js": () => import("./../../src/pages/actualites.js" /* webpackChunkName: "component---src-pages-actualites-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jeudedonnees-js": () => import("./../../src/pages/jeudedonnees.js" /* webpackChunkName: "component---src-pages-jeudedonnees-js" */),
  "component---src-pages-page-2-js": () => import("./../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-publications-js": () => import("./../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */)
}

