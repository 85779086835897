import "./src/css/style.css"
import "./src/css/w3.css"
import "./src/css/responsive.css"
import "./src/css/colors.css"
import "./src/css/animate.css"
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/umd/popper.js'
import './src/js/scripts.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'wowjs/dist/wow.js';
import "bootstrap/dist/css/bootstrap.min.css";
const addScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.async = true
    document.body.appendChild(script)
  }
  
  export const onClientEntry = () => {
    window.onload = () => {
        addScript("https://public.flourish.studio/resources/embed.js")
    }
  }
  
